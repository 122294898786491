import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import React, { lazy, Suspense } from 'react';

const LazyRunOrchestrations = lazy(
  () =>
    import(/* webpackChunkName: "run-orchestrations" */ './RunOrchestrations')
);

const RunOrchestrationsLazy = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => {
  return (
    <CsbErrorBoundary>
      <Suspense
        fallback={<TableOverlayComponent page={PAGE_OPTIONS.generalPage} />}
      >
        <LazyRunOrchestrations {...props} />
      </Suspense>
    </CsbErrorBoundary>
  );
};

export default RunOrchestrationsLazy;
