import {
  ColDef,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ITableContainerProps } from 'interfaces/table.interface';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './DeployModulesTables.module.scss';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import {
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';
import CellDeployModuleName from 'components/CellDeployModuleName/CellDeployModuleName';
import CellDeployModuleVersion from 'components/CellDeployModuleVersion/CellDeployModuleVersion';

const defaultColumnDef: ColDef = {
  width: 200,
  sortable: true,
  suppressColumnsToolPanel: true,
  icons: {
    sortAscending: '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
    sortDescending:
      '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>',
  },
};

interface IDeployModulesTablesProps extends ITableContainerProps {
  items: IDeployModuleSearchRow[] | IDeployModuleServerRow[];
  type: 'search-table' | 'server-table';
}

const gridOptionComponents = {
  cellDeployModuleName: CellDeployModuleName,
  cellDeployModuleVersion: CellDeployModuleVersion,
};

const DeployModulesTables = (props: IDeployModulesTablesProps): JSX.Element => {
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const [loading, setLoading] = useState(true);

  const updateLoadingOverlay = () => {
    if (gridApiRef.current) {
      if (loading) {
        gridApiRef.current.showLoadingOverlay();
      } else {
        gridApiRef.current.hideOverlay();
      }
    }
  };

  useEffect(() => {
    updateLoadingOverlay();
  }, [loading]);

  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    if (gridApiRef.current) {
      adjustColumnsWidth();
      setLoading(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
    updateLoadingOverlay();
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const adjustColumnsWidth = () => {
    if (gridColumnApiRef) {
      gridColumnApiRef.current?.autoSizeAllColumns();
      gridApiRef.current?.sizeColumnsToFit();
    }
  };

  const getRowId = useCallback((params) => params.data.id, []);

  const gridOptions: GridOptions = {
    components: gridOptionComponents,
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: false,
    suppressRowVirtualisation: false,
    rowBuffer: 20,
    localeText: {
      search: 'Start typing...',
    },
    defaultColDef: defaultColumnDef,
    headerHeight: 40,
    rowHeight: 40,
    domLayout: 'normal',
    loadingOverlayComponent: TableOverlayComponent,
    loadingOverlayComponentParams: { page: props.page, style: 'default' },
    overlayNoRowsTemplate: '<span>No results</span>',
    animateRows: true,
    onGridReady,
    onGridSizeChanged,
    onFirstDataRendered,
  };

  return (
    <CsbErrorBoundary>
      <div className={style['csb-table']}>
        <div
          className={style['wk-advanced-table-container']}
          data-testid="TableContainer"
          id="deploy-modules-table"
        >
          <div
            className={`wk-advanced-table ${style['csb-table__content']} ${
              props.additionalStyles ?? style['wk-striped']
            }`}
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={props.items}
              getRowId={getRowId}
              {...props.additionalData}
            >
              {props.columns.map((column) => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default DeployModulesTables;
