import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import React, { lazy, Suspense } from 'react';

const LazyManageModules = lazy(
  () => import(/* webpackChunkName: "manage-modules" */ './ManageModules')
);

const ManageModulesLazy = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <CsbErrorBoundary>
    <Suspense
      fallback={<TableOverlayComponent page={PAGE_OPTIONS.generalPage} />}
    >
      <LazyManageModules {...props} />
    </Suspense>
  </CsbErrorBoundary>
);

export default ManageModulesLazy;
