import {
  Iconsvg,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ICloseModalOptions } from 'interfaces/modals/close-modal-info.interface';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { closeModal } from '../../../app/modal/ModalSlice';
import { MODAL_FORMS_LIST, MODAL_FOOTER_LIST } from '../modal-list';
import style from './ModalContainer.module.scss';

const ModalContainer = () => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => state.modal);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm();

  const CustomComponent = MODAL_FORMS_LIST[modalInfo.componentName];
  const CustomFooter = modalInfo.footerName
    ? MODAL_FOOTER_LIST[modalInfo.footerName ?? 'cancel-reset-apply']
    : null;
  const customRef: React.RefObject<any> = React.createRef();
  const resetRef = React.createRef();
  const applyRef = React.createRef();

  const handleCloseModal = (event: ICloseModalOptions) => {
    reset();
    dispatch(
      closeModal({
        id: modalInfo.id,
        action: event,
      })
    );
  };

  const checkIfValid = (apply: boolean, reset: boolean) => {
    if (resetRef.current && applyRef.current) {
      (resetRef.current as HTMLButtonElement).disabled = !reset;
      (applyRef.current as HTMLButtonElement).disabled = !apply;
    } else {
      setTimeout(() => {
        checkIfValid(apply, reset);
      }, 100);
    }
  };

  return (
    <CsbErrorBoundary>
      <div data-testid="ModalElement" className={style['']}>
        <Modal
          isOpen={modalInfo.display}
          onUserRequest={(event: ICloseModalOptions) => handleCloseModal(event)}
          width={modalInfo.width}
        >
          <ModalHeader
            titleId={'modal-id-001'}
            descriptionId={'modal-id-002'}
            onUserRequest={(event: ICloseModalOptions) =>
              handleCloseModal(event)
            }
            isFullscreenButtonVisible
          >
            {modalInfo.title}
          </ModalHeader>
          <CsbErrorBoundary>
            <ModalBody>
              {modalInfo.componentName ? (
                <CustomComponent
                  {...modalInfo.componentProps}
                  ref={customRef}
                  onValueChanges={checkIfValid}
                  register={register}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  watch={watch}
                />
              ) : (
                modalInfo.body
              )}
            </ModalBody>
          </CsbErrorBoundary>
          <ModalFooter>
            {modalInfo.withFooter ? (
              <CustomFooter
                {...modalInfo.componentProps}
                ref={{ resetRef, applyRef, customRef }}
                handleSubmit={handleSubmit}
                reset={reset}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
              />
            ) : null}
          </ModalFooter>
        </Modal>
      </div>
    </CsbErrorBoundary>
  );
};

export default ModalContainer;
