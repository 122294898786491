import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import styles from './DeployModuleModal.module.scss';
import { InputField } from '@wk/components-react16/dist/input-field/input-field';
import { ButtonGroupItem } from '@wk/components-react16/dist/button-group-item/button-group-item';
import { ButtonField } from '@wk/components-react16/dist/button-field/button-field';
import { useEffect, useRef, useState } from 'react';
import DeployModulesTables from 'components/TableContainer/DeployModulTables/DeployModulesTables';
import {
  MANAGE_MODULES_DEPLOY_SEARCH_RESULTS,
  MANAGE_MODULES_DEPLOY_SELECT_SERVERS,
} from 'pages/ManageModules/ManageModules.constants';
import {
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';
import {
  fetchEC2Servers,
  fetchModulesCandidates,
} from 'api/manageModules/manageModulesThunks';
import { useAppDispatch } from 'app/hooks';
import { addToast } from 'app/toast/toastSlice';
import { useAppSelector } from 'app/hooks';
import { selectNotifications } from 'app/toast/toastSlice';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';

export default function DeployModuleModal(props: any) {
  const [enableIndex, setEnableIndex] = useState(0);
  const [modules, setModules] = useState<IDeployModuleSearchRow[] | undefined>(
    undefined
  );
  const [servers, setServers] = useState<IDeployModuleServerRow[] | undefined>(
    undefined
  );

  return (
    <CsbErrorBoundary>
      <div
        className={`${styles['deploy-module-modal-container']}`}
        data-testid="DeployModuleModal"
      >
        <SelectModuleSection
          {...props}
          setEnableIndex={setEnableIndex}
          setServers={setServers}
          setModules={setModules}
        />
        <div className={enableIndex < 1 ? styles['disabled-section'] : ''}>
          <SearchResultsSection
            {...props}
            setEnableIndex={setEnableIndex}
            modules={modules}
          />
        </div>
        <div className={enableIndex < 2 ? styles['disabled-section'] : ''}>
          <SelectServersSection {...props} servers={servers} />
        </div>
      </div>
    </CsbErrorBoundary>
  );
}

const SelectModuleSection = (props: any) => {
  const nameRef = useRef<HTMLInputElement>();
  const versionRef = useRef<HTMLInputElement>();
  const name = props.watch('name');
  const version = props.watch('version');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const toasters = useAppSelector(selectNotifications);
  const [loading, setLoading] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsButtonDisabled(
      e.target.value.trim() === '' || e.target.value?.length < 3
    );
  };

  const searchModule = async () => {
    if (name !== '') {
      setLoading(true);
      try {
        const response = await dispatch(
          fetchModulesCandidates({
            artifactId: name.endsWith('*') ? name : `${name}*`,
            version: version !== '' ? version : '*',
          })
        ).unwrap();
        if (response.length > 0) {
          props.setModules(
            response.map((module: IDeployModuleSearchRow) => ({
              ...module,
              selectionMethod: () => {
                props.setEnableIndex(2);
              },
            }))
          );
          props.setEnableIndex(1);
          const serversResponse = await dispatch(fetchEC2Servers()).unwrap();
          props.setServers(serversResponse);
        } else {
          if (
            toasters?.length === 0 ||
            toasters?.findIndex((t: any) => t.id.includes('no-modules')) === -1
          ) {
            dispatch(
              addToast({
                id: `no-modules-${(Math.random() + 1).toString()}`,
                type: 'informationToast',
                status: 'warning',
                additionalData: {
                  text: 'No modules match your search criteria',
                },
              })
            );
          }
        }
      } catch (error) {
        dispatch(
          addToast({
            id: `no-modules-${(Math.random() + 1).toString()}`,
            type: 'informationToast',
            status: 'error',
            additionalData: {
              text:
                consoleErrorMessage(error) ??
                'There was an error while retrieving the modules, try again',
            },
          })
        );
      }
      setLoading(false);
    }
  };

  return (
    <CsbErrorBoundary>
      <span className={styles['header']}>Select module</span>
      <form
        className={styles['select-module-container']}
        data-testid="SelectModuleSection"
      >
        <InputField label="Name" labelFor="namelbl" size="large">
          <input
            ref={nameRef}
            type="text"
            id="namelbl"
            name="namelbl"
            placeholder="Enter Maven artifact ID for the required module (min 3 characters)"
            data-testid={`Input-name`}
            {...props.register('name', {
              onChange: handleNameChange,
            })}
          />
        </InputField>
        <InputField
          label="Version (optional)"
          labelFor="versionlbl"
          size="large"
        >
          <input
            ref={versionRef}
            type="text"
            id="versionlbl"
            name="versionlbl"
            placeholder="Enter version number or * for all versions"
            data-testid={`Input-version`}
            {...props.register('version')}
          />
        </InputField>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'}>
            <button
              type="submit"
              data-testid="SearchButton"
              id="submit"
              onClick={() => searchModule()}
              disabled={isButtonDisabled || loading}
            >
              Search
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </form>
    </CsbErrorBoundary>
  );
};

const SearchResultsSection = (props: any) => {
  return (
    <CsbErrorBoundary>
      <div
        className={styles['search-results-container']}
        data-testid="SearchResultsSection"
      >
        <span className={styles['header']}>Search results</span>
        <span className={styles['subtitle']}>
          Select the module and version number you want to deploy
        </span>
        <DeployModulesTables
          items={props.modules ?? [{}]}
          type="search-table"
          columns={MANAGE_MODULES_DEPLOY_SEARCH_RESULTS}
        />
      </div>
    </CsbErrorBoundary>
  );
};

const SelectServersSection = (props: any) => {
  return (
    <CsbErrorBoundary>
      <div
        className={styles['search-results-container']}
        data-testid="ServerResultsSection"
      >
        <span className={styles['header']}>Select servers</span>
        <span className={styles['subtitle']}>
          Select one or more servers to deploy the selected modules to
        </span>
        <DeployModulesTables
          items={props.servers ?? [{}]}
          type="server-table"
          columns={MANAGE_MODULES_DEPLOY_SELECT_SERVERS}
        />
      </div>
    </CsbErrorBoundary>
  );
};
