import React, { useCallback, useEffect } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectModalName } from 'app/modal/ModalSlice';
import { selectSelectedWorkflow } from 'app/runOrchestrations/RunOrchestrationsSlice';
import { MODAL_NAMES } from 'components/Modals/modal-list';
import { InputField, SelectField } from '@wk/components-react16';
import { IWorkflowProduct } from 'interfaces/runOrchestration/workflow-row';
import style from './CreateProductParams.module.scss'; // do not remove this line
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { MAX_INTEGER_CSB } from 'utils/common-constants';

type ParameterType = 'text' | 'select';

interface Parameter {
  name: string;
  paramId: keyof IWorkflowProduct;
  type: ParameterType;
  options?: string[];
}

interface ICreateProductParams {
  register: UseFormRegister<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: Partial<FieldErrorsImpl<FieldValues>>;
}

export default function CreateProductParams({
  register,
  setError,
  clearErrors,
  setValue,
  errors,
}: ICreateProductParams) {
  const selectedOrchestration = useAppSelector(selectSelectedWorkflow);
  const modalName = useAppSelector(selectModalName);
  useEffect(() => {
    if (modalName === MODAL_NAMES.CREATE_PRODUCT) {
      setError('name', { type: 'custom', message: 'This field is required' });
    }
    if (modalName === MODAL_NAMES.EDIT_PRODUCT && selectedOrchestration) {
      Object.entries(selectedOrchestration).forEach(([key, value]) => {
        if (value) {
          setValue(key as keyof IWorkflowProduct, value);
        }
      });
    }
  }, [modalName, selectedOrchestration, setError, setValue]);

  const handleSetError = useCallback(
    (name: string, error: { type: string; message: string }) => {
      setError(name, error);
    },
    [setError]
  );

  const handleClearErrors = useCallback(
    (name: string) => {
      clearErrors(name);
    },
    [clearErrors]
  );

  const parameters: Parameter[] = [
    { name: 'Product Name', paramId: 'name', type: 'text' },
    { name: 'Business Product', paramId: 'businessProduct', type: 'text' },
    { name: 'Throttle Rate', paramId: 'throttleRate', type: 'text' },
    { name: 'Cron Trigger', paramId: 'cronTrigger', type: 'text' },
    { name: 'Cron Timezone', paramId: 'cronTimezone', type: 'text' },
    { name: 'SFTP Dropzone', paramId: 'sftpDropzone', type: 'text' },
    { name: 'SFTP Private Key', paramId: 'sftpPrivateKey', type: 'text' },
    { name: 'FTP Dropzone', paramId: 'ftpDropzone', type: 'text' },
    { name: 'S3 Dropzone', paramId: 's3Dropzone', type: 'text' },
    { name: 'Notification Emails', paramId: 'notifyEmails', type: 'text' },
    {
      name: 'Event Params Mapping',
      paramId: 'eventParametersMapping',
      type: 'text',
    },
    { name: 'Reporting Index', paramId: 'reportingIndex', type: 'text' },
    {
      name: 'UnpackProduct',
      paramId: 'unpackInput',
      type: 'select',
      options: ['false', 'true'],
    },
  ];

  return (
    <div data-testid="CreateProductParams">
      {parameters.map((param) => (
        <MemoizedFormField
          key={param.paramId}
          param={param}
          register={register}
          setValue={setValue}
          errors={errors}
          setError={handleSetError}
          clearErrors={handleClearErrors}
          modalName={modalName}
          selectedOrchestration={selectedOrchestration}
        />
      ))}
    </div>
  );
}

const MemoizedFormField = React.memo(FormField);
function FormField({
  param,
  register,
  setValue,
  errors,
  setError,
  clearErrors,
  modalName,
  selectedOrchestration,
}: any) {
  const validateName = (
    value: string,
    errors: any,
    setError: any,
    clearErrors: any
  ) => {
    if (!value) {
      if (!errors.name) {
        setError('name', { type: 'custom', message: 'This field is required' });
      }
    } else if (errors.name) {
      clearErrors('name');
    }
  };

  const validateThrottleRate = (
    value: string,
    errors: any,
    setError: any,
    clearErrors: any
  ) => {
    const isValid =
      value === 'null' ||
      value === '' ||
      (!isNaN(+value) &&
        parseInt(value) >= 0 &&
        parseInt(value) <= MAX_INTEGER_CSB);
    if (isValid && errors.throttleRate) {
      clearErrors('throttleRate');
    } else if (!isValid && !errors.throttleRate) {
      setError('throttleRate', {
        type: 'custom',
        message: 'Use only positive numbers or null',
      });
    }
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { id, value } = event.target;
      setValue(id, value);

      if (id === 'name') {
        validateName(value, errors, setError, clearErrors);
      } else if (id === 'throttleRate') {
        validateThrottleRate(value, errors, setError, clearErrors);
      }
    },
    [setValue, setError, clearErrors, errors]
  );

  if (param.type === 'select') {
    return (
      <SelectField label={param.name} labelFor={param.paramId}>
        <select
          id={param.paramId}
          data-testid={`Select-${param.paramId}`}
          {...register(param.paramId)}
          onChange={handleChange}
          defaultValue={
            modalName === MODAL_NAMES.EDIT_PRODUCT
              ? selectedOrchestration?.[
                  param.paramId as keyof IWorkflowProduct
                ]?.toString()
              : ''
          }
        >
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </SelectField>
    );
  }

  return (
    <InputField
      label={param.name}
      labelFor={param.paramId}
      errors={
        errors[param.paramId]
          ? [{ label: errors[param.paramId].message, labelId: param.paramId }]
          : undefined
      }
    >
      <input
        type={param.type}
        id={param.paramId}
        data-testid={`Input-${param.paramId}`}
        {...register(param.paramId, {
          onChange: handleChange,
        })}
        defaultValue={
          modalName === MODAL_NAMES.EDIT_PRODUCT
            ? selectedOrchestration?.[
                param.paramId as keyof IWorkflowProduct
              ]?.toString()
            : undefined
        }
      />
    </InputField>
  );
}
