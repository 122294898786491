import {
  IDeployModuleSearchResponse,
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
  IDeployModuleServersResponse,
} from 'interfaces/modules/module.interface';
import { Useuid } from 'utils/hooks/useUid';

export const transformModuleServersResponse = (
  response: IDeployModuleServersResponse
): IDeployModuleServerRow[] => {
  const result: IDeployModuleServerRow[] = [];
  let idCounter = 0;

  for (const [name, ec2Servers] of Object.entries(response)) {
    for (const ec2Server of ec2Servers) {
      result.push({
        id: idCounter++,
        name,
        ec2Server,
        type: 'select-servers',
      });
    }
  }

  return result;
};

export const combineModuleVersions = (
  modules: IDeployModuleSearchResponse[]
) => {
  const combinedModules: IDeployModuleSearchRow[] = [];

  modules.forEach((module, index) => {
    const duplicate = combinedModules.find(
      (el: IDeployModuleSearchRow) => el.name === module.artifactId
    );
    if (duplicate) {
      if (!duplicate.allVersions.includes(module.version)) {
        duplicate.allVersions.push(module.version);
        duplicate.allVersions.sort(sortModuleVersions);
        duplicate.selectedVersion = duplicate.allVersions[0];
      }
    } else {
      const moduleToPush = {
        name: module.artifactId,
        id: Useuid(),
        type: 'search-results',
        allVersions: [module.version],
        selectedVersion: module.version,
      } as IDeployModuleSearchRow;

      combinedModules.push(moduleToPush);
    }
  });
  return combinedModules;
};

function sortModuleVersions(first: any, second: any) {
  // Helper function to parse a version string into comparable parts
  function parseVersion(version: any) {
    return version.split(/[-.]/).map((part: any) => {
      const num = parseInt(part, 10);
      return isNaN(num) ? part : num;
    });
  }

  // Helper function to compare two version parts
  function compareVersionParts(a: any, b: any) {
    if (typeof a === 'number' && typeof b === 'number') {
      return b - a; // Descending order for numbers
    }
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b) * -1; // Descending order for strings
    }
    return typeof a === 'number' ? -1 : 1; // Numbers before strings
  }

  try {
    const firstParts = parseVersion(first);
    const secondParts = parseVersion(second);

    for (let i = 0; i < Math.max(firstParts.length, secondParts.length); i++) {
      const firstPart = firstParts[i];
      const secondPart = secondParts[i];

      if (firstPart === undefined) return 1;
      if (secondPart === undefined) return -1;

      const comparison = compareVersionParts(firstPart, secondPart);
      if (comparison !== 0) return comparison;
    }

    return 0; // Versions are equal
  } catch (error) {
    console.error(`Error comparing versions ${first} and ${second}:`, error);
    return 0; // Return equal if there's an error
  }
}
