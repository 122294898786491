import {
  StepItem,
  Stepthrough,
  WizardStepthrough,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectSelectedWorkflow,
  selectWizardActiveIndex,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import React, { useEffect } from 'react';
import style from './CreateProductModal.module.scss';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import CreateProductParams from './Steps/CreateProductParams/CreateProductParams';
import SetProductParametersStep from './Steps/SetProductParametersStep/SetProductParametersStep';
import {
  selectAdditionalData,
  selectModalName,
  setAdditionalData,
} from 'app/modal/ModalSlice';
import { IWorkflowProduct } from 'interfaces/runOrchestration/workflow-row';
import { MODAL_NAMES } from 'components/Modals/modal-list';

export enum CREATE_PRODUCT_WIZARD_STEPS {
  DEFINE_ORCHESTRARION_PARAMS = 'Define orchestration parameters',
  DEFINE_STEP_PARAMS = 'Define step parameters',
}

export interface ICreateProductModal {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const CreateProductModal = ({
  register,
  setValue,
  setError,
  clearErrors,
  errors,
}: ICreateProductModal) => {
  const wizardActiveindex = useAppSelector(selectWizardActiveIndex);
  const modalAdditionalData = useAppSelector(selectAdditionalData);
  const selectedOrchestration = useAppSelector(selectSelectedWorkflow);
  const modalName = useAppSelector(selectModalName);
  const dispatch = useAppDispatch();
  const wizardItems: CREATE_PRODUCT_WIZARD_STEPS[] = [
    CREATE_PRODUCT_WIZARD_STEPS.DEFINE_ORCHESTRARION_PARAMS,
    CREATE_PRODUCT_WIZARD_STEPS.DEFINE_STEP_PARAMS,
  ];

  useEffect(() => {
    dispatch(setAdditionalData({ ...modalAdditionalData, wizardItems }));
  }, []);

  useEffect(() => {
    if (modalName === MODAL_NAMES.CREATE_PRODUCT) {
      setValue('workflowId', selectedOrchestration?.id);
    } else if (
      selectedOrchestration?.isProduct &&
      modalName === MODAL_NAMES.EDIT_PRODUCT
    ) {
      setValue(
        'productId',
        (selectedOrchestration as IWorkflowProduct).productId
      );
      setValue(
        'workflowId',
        (selectedOrchestration as IWorkflowProduct)?.parentId
      );
    }
  }, [selectedOrchestration]);

  return (
    <CsbErrorBoundary>
      {wizardItems && (
        <>
          {wizardItems.length > 0 && (
            <div className={style['wizard-container']}>
              <WizardStepthrough>
                <div
                  slot="xs"
                  style={{ padding: '2rem 0.5rem 1rem 0' }}
                  data-testid="Wizard-xs"
                >
                  <Stepthrough mode="compact" activeIndex={wizardActiveindex}>
                    {wizardItems?.map((step, i) => {
                      return (
                        <React.Fragment key={'cogen-20411' + i.toString()}>
                          <StepItem slot="stepItem">
                            <span
                              className={
                                i === 0 ? style['step-0'] : style['step']
                              }
                              data-testid={`StepItem-${i}`}
                            >
                              {step}
                            </span>
                          </StepItem>
                        </React.Fragment>
                      );
                    })}
                  </Stepthrough>
                </div>
                <div slot="default" data-testid="Wizard-default">
                  <Stepthrough mode="normal" activeIndex={wizardActiveindex}>
                    {wizardItems?.map((step, i) => {
                      return (
                        <React.Fragment key={'cogen-20416' + i.toString()}>
                          <StepItem slot="stepItem">
                            <span
                              className={
                                i === 0 ? style['step-0'] : style['step']
                              }
                              data-testid={`StepItem-${i}`}
                            >
                              {step}
                            </span>
                          </StepItem>
                        </React.Fragment>
                      );
                    })}
                  </Stepthrough>
                </div>
              </WizardStepthrough>
            </div>
          )}
          <div
            className={style['create-product-modal-container']}
            data-testid="CreateProductModal"
          >
            <div
              className={
                style[`${wizardActiveindex === 0 ? 'display' : 'hide'}`]
              }
            >
              <div
                className={`${style['step-content-container']}`}
                data-testid="Step-0"
              >
                <CreateProductParams
                  register={register}
                  setError={setError}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  errors={errors}
                />
              </div>
            </div>
            <div
              className={
                style[`${wizardActiveindex === 1 ? 'display' : 'hide'}`]
              }
            >
              <span className={style['step-header']}>Set parameters</span>
              <span className={style['step-subtitle']}>
                Update the default parameters for each orchestration step, if
                needed, or accept them by clicking 'Submit'.
              </span>
              <div
                data-testid="Step-1"
                className={`${style['step-content-container']} ${style['parameters-step']}`}
              >
                <SetProductParametersStep
                  selectedOrchestration={selectedOrchestration}
                  register={register}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </CsbErrorBoundary>
  );
};

export default CreateProductModal;
