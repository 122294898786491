import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IToast } from 'interfaces/toasts';
import {
  ButtonField,
  Notification,
  NotificationActions,
} from '@wk/components-react16';
import { removeToast } from 'app/toast/toastSlice';
import {
  capitalizeFirstLetter,
  consoleErrorMessage,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useState, useRef, useEffect } from 'react';
import {
  useRemoveAllModulesMutation,
  useRestartAllModulesMutation,
  useStopAllModulesMutation,
} from 'api/manageModules/modulesApi';
import { selectSelectedModule } from 'app/manageModules/ManageModulesSlice';
import { IModuleServer } from 'interfaces/modules/module.interface';
import { IServerWorkflow } from 'interfaces/workflow.interface';

export interface IInlineActionModuleButtonsToast {
  item: IToast;
}

export enum INLINE_ACTION_MODULE_TYPES {
  'stop' = 'stop',
  'restart' = 'restart',
  'remove' = 'remove',
}

const actionTexts = {
  [INLINE_ACTION_MODULE_TYPES.stop]:
    'Are you sure you want to stop this module?',
  [INLINE_ACTION_MODULE_TYPES.restart]:
    'Are you sure you want to restart this module?',
  [INLINE_ACTION_MODULE_TYPES.remove]:
    'Are you sure you want to remove this module?',
};

const buttonTexts = {
  [INLINE_ACTION_MODULE_TYPES.stop]: 'Stop',
  [INLINE_ACTION_MODULE_TYPES.restart]: 'Restart',
  [INLINE_ACTION_MODULE_TYPES.remove]: 'Remove',
};

export const InlineActionModuleButtonsToast = ({
  item,
}: IInlineActionModuleButtonsToast) => {
  const dispatch = useAppDispatch();
  const selectedModule: IModuleServer = useAppSelector(selectSelectedModule);
  const [stopAllModulesInstances] = useStopAllModulesMutation();
  const [restartAllModuleInstances] = useRestartAllModulesMutation();
  const [removeAllModuleInstances] = useRemoveAllModulesMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [openStatus, setOpenStatus] = useState(true);
  const container = useRef<HTMLDivElement>(null);
  const isFirstClick = useRef(false);
  const actionType = item?.additionalData?.id as INLINE_ACTION_MODULE_TYPES;

  const handleAction = async () => {
    setIsLoading(true);
    try {
      if (actionType === INLINE_ACTION_MODULE_TYPES.stop) {
        await stopAllModulesInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        showSuccessToast(
          'Your module has been stopped. It may take a while for the status to be updated.'
        );
      } else if (actionType === INLINE_ACTION_MODULE_TYPES.restart) {
        await restartAllModuleInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        showSuccessToast('Your Module has been restarted.');
      } else if (actionType === INLINE_ACTION_MODULE_TYPES.remove) {
        const response = await removeAllModuleInstances({
          moduleId: selectedModule.id,
          slave: selectedModule.slave,
        }).unwrap();
        handleRemoveResponse(response);
      }
    } catch (error) {
      showErrorToast(
        consoleErrorMessage(error) ??
          `Unable to ${actionType} module: An unknown error occurred.`
      );
    }
    dispatch(removeToast(item.id));
    setIsLoading(false);
  };

  const handleRemoveResponse = (response: IServerWorkflow[]) => {
    if (response.length > 0) {
      const activeWorkflows = response
        .map((workflow: IServerWorkflow) => workflow.name)
        .join(', ');
      showErrorToast(
        `Unable to remove module: Orchestrations using this module: ${activeWorkflows}.`
      );
    } else {
      showSuccessToast('Your module has been removed.');
    }
  };

  const showSuccessToast = (text: string) => {
    showToastAndClose('success', 'informationToast', () => {}, dispatch, {
      text,
    });
  };

  const showErrorToast = (text: string) => {
    showToastAndClose('error', 'informationToast', () => {}, dispatch, {
      text,
    });
  };

  const handleCancel = () => {
    setOpenStatus(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      openStatus &&
      container.current &&
      !container.current.contains(event.target) &&
      isFirstClick.current
    ) {
      setOpenStatus(false);
    } else {
      isFirstClick.current = true;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <CsbErrorBoundary>
      <div
        data-testid={`InlineActionModuleButtonsToast-${item.id}`}
        ref={container}
      >
        <Notification
          controlMode="uncontrolled"
          isOpen={openStatus}
          mode="toast"
          type="warning"
          autoCloseTimeout={0}
          hasCloseButton
          heading={capitalizeFirstLetter('warning')}
          onCloseEnd={() => dispatch(removeToast(item.id))}
          key={item.id}
        >
          <span slot="notificationContent">
            <span>{actionTexts[actionType]}</span>
          </span>
          <NotificationActions slot="notificationActions">
            <ButtonField mode={'text'} size={'small'}>
              <button
                type="button"
                onClick={handleAction}
                disabled={isLoading}
                data-testid={'ActionBtn'}
              >
                {buttonTexts[actionType]}
              </button>
            </ButtonField>
            <ButtonField mode={'text'} size={'small'}>
              <button
                type="button"
                onClick={handleCancel}
                disabled={isLoading}
                data-testid={'CancelBtn'}
              >
                Cancel
              </button>
            </ButtonField>
          </NotificationActions>
        </Notification>
      </div>
    </CsbErrorBoundary>
  );
};
