import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IErrorLogFromServer } from 'interfaces/error-log-row.interface';
import { ISubmitWorkflow } from 'interfaces/modals/configure-and-run.interface';
import { IOrchestrationServer } from 'interfaces/orchestration.interface';
import { IPostJobsInfo } from 'interfaces/post-jobs-info.interface';
import { IServiceNowEmail } from 'interfaces/service-now-modal';
import { uuidSignal } from 'utils/hooks/useSSE';

export const orchestrationApi = createApi({
  reducerPath: 'orchestrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/csb',
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrchestrationById: builder.query<IOrchestrationServer, string>({
      query: (id: string) => {
        return { url: `jobs/${id}`, credentials: 'include' };
      },
      keepUnusedDataFor: 0,
    }),
    getUsersAll: builder.query<any, any>({
      query: () => {
        return { url: 'users/all', credentials: 'include' };
      },
      keepUnusedDataFor: 1,
    }),
    getS3Files: builder.query<any, any>({
      query: (data: {
        bucketName: string;
        accessKey: string;
        privateKey: string;
        folder?: string;
      }) => {
        return {
          url: `jobs/list-s3/${data.bucketName}/${data.accessKey}/${
            data.privateKey
          }${data.folder ? data.folder : ''}`,
          credentials: 'include',
        };
      },
      keepUnusedDataFor: 300,
    }),
    //mutations
    postJobs: builder.mutation<any, IPostJobsInfo>({
      query: (data: IPostJobsInfo) => {
        return {
          url: `jobs/sse/${uuidSignal}`,
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
    }),
    postDownloadLog: builder.mutation<any, { CSBJobId: any; fromTimeCSB: any }>(
      {
        query: (data: { CSBJobId: any; fromTimeCSB: any }) => {
          return {
            url: `jobs/download-log`,
            method: 'POST',
            body: data,
            responseHandler: (response: Response) => response?.text(),
            credentials: 'include',
          };
        },
        transformResponse(baseQueryReturnValue, meta, arg) {
          return {
            data: baseQueryReturnValue,
            contentType: meta?.response?.headers.get('Content-Type'),
            name: meta?.response?.headers.get('file-name'),
          };
        },
      }
    ),
    postViewLog: builder.mutation<IErrorLogFromServer[], Object>({
      query: (data: Object) => {
        return {
          url: `jobs/log`,
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
    }),
    postResubmit: builder.mutation<any, number>({
      query: (id: number) => {
        return {
          url: `jobs/${id}/resubmit`,
          method: 'POST',
          body: {},
          credentials: 'include',
        };
      },
    }),
    postStop: builder.mutation<any, number>({
      query: (id: number) => {
        return {
          url: `jobs/${id}/stop`,
          method: 'POST',
          body: {},
          credentials: 'include',
        };
      },
    }),
    postSendServiceNowEmail: builder.mutation<any, IServiceNowEmail>({
      query: (emailData: IServiceNowEmail) => {
        return {
          url: `jobs/sendEmail/${emailData.orchestrationId}`,
          method: 'POST',
          body: { additionalInformation: emailData.additionalInformation },
          credentials: 'include',
        };
      },
    }),
    submitWorkflow: builder.mutation<any, ISubmitWorkflow>({
      query: (data: ISubmitWorkflow) => {
        return {
          url: `jobs/${
            data.productName
              ? `${data.name}/${data.productName}`
              : `${data.name}`
          }`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: { ...data.data },
          credentials: 'include',
        };
      },
    }),
    uploadContent: builder.mutation<any, File[]>({
      query: (content: File[]) => {
        const formData = new FormData();
        content.forEach((file, index) => {
          formData.append(`${index}`, file);
        });
        return {
          url: `jobs/content/upload`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: formData,
          responseHandler: (response: Response) => response?.text(),
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useGetUsersAllQuery,
  useLazyGetOrchestrationByIdQuery,
  usePostJobsMutation,
  usePostDownloadLogMutation,
  usePostViewLogMutation,
  usePostResubmitMutation,
  usePostStopMutation,
  usePostSendServiceNowEmailMutation,
  useSubmitWorkflowMutation,
  useGetS3FilesQuery,
  useLazyGetS3FilesQuery,
  useUploadContentMutation,
} = orchestrationApi;
