import { AsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  FilterChangedEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { ColumnApi, GridApi } from 'ag-grid-enterprise';
import {
  addSelectedOrchestration,
  removeSelectedOrchestration,
  resetSort,
  SelectActiveFilterType,
  SelectFilterInfoType,
  SelectFilterModelType,
  SelectPaginationInfoType,
  setFilterInfo,
  setFilterModel,
  setPaginatorInfo,
} from 'app/dashboard/DashboardSlice';
import { TABLE_FIELDS } from 'utils/common-constants';
import { mapJobToTableData } from 'dto/jobToTable/jobToTable';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import {
  IPostJobsInfo,
  PostJobsInfoSortigFields,
} from 'interfaces/post-jobs-info.interface';
import {
  IOnGridReadyAdditionalMethod,
  PAGE_OPTIONS,
} from 'interfaces/table.interface';
import { MutableRefObject } from 'react';
import {
  deleteTableFiltersFromDataToSend,
  setFilterDataToSend,
} from './filterUtils';
import {
  consoleErrorMessage,
  isObjectsAreEqualDeep,
} from 'utils/commonFunctions/CommonFunctions';
import { IJobStartedByUser } from 'interfaces/user.interface';
import { IOrchestrationServer } from 'interfaces/orchestration.interface';

//Sorting
export const handleSort = (
  sorted: any,
  filterInfo: IPostJobsInfo | undefined,
  dispatch: ThunkDispatch<any, any, any>,
  gridColumnApiRef: MutableRefObject<ColumnApi | null>,
  gridApiRef: MutableRefObject<GridApi<any> | null>,
  page: PAGE_OPTIONS | undefined
) => {
  if (!sorted) {
    dispatch(resetSort());
  }
  const sortedId: TABLE_FIELDS = sorted?.colId;
  const sortOrder: 'asc' | 'desc' = sorted?.sort === 'asc' ? 'desc' : 'asc';
  if (
    sorted &&
    sortedId !== TABLE_FIELDS.status &&
    sortedId !== TABLE_FIELDS.step &&
    sortedId !== TABLE_FIELDS.id
  ) {
    const filterIds = {
      [TABLE_FIELDS.name]: 'ORCHESTRATION_NAME',
      [TABLE_FIELDS.startTime]: 'START_TIME',
      [TABLE_FIELDS.endTime]: 'END_TIME',
      [TABLE_FIELDS.user]: 'USER',
      [TABLE_FIELDS.duration]: 'DURATION',
      //run orchestration
      [TABLE_FIELDS.description]: 'DESCRIPTION',
      [TABLE_FIELDS.worker]: 'WORKER',
      [TABLE_FIELDS.throttleRate]: 'THROTTLE_RATE',
      [TABLE_FIELDS.options]: 'OPTIONS',
      // error-log modal fields
      [TABLE_FIELDS.createdTime]: 'CREATED_TIME',
      [TABLE_FIELDS.message]: 'MESSAGE',
      [TABLE_FIELDS.severity]: 'SEVERITY',
      //installed modules fields
      [TABLE_FIELDS.version]: 'VERSION',
      [TABLE_FIELDS.workers]: 'WORKERS',
      [TABLE_FIELDS.consumers]: 'CONSUMERS',
      [TABLE_FIELDS.totalCapacity]: 'TOTAL_CAPACITY',
      //module details fields
      [TABLE_FIELDS.type]: 'TYPE',
      [TABLE_FIELDS.required]: 'REQUIRED',
      [TABLE_FIELDS.default]: 'DEFAULT',
    };
    console.log('Sorting', {
      ...filterInfo,
      activePage: 1,
      sortingField: filterIds[sortedId] as PostJobsInfoSortigFields,
      sortingType: sortOrder.toUpperCase() as 'ASC' | 'DESC',
    });
    dispatch(
      setFilterInfo({
        ...filterInfo,
        activePage: 1,
        sortingField: filterIds[sortedId] as PostJobsInfoSortigFields,
        sortingType: sortOrder.toUpperCase() as 'ASC' | 'DESC',
      })
    );
  }
  const columnState = gridColumnApiRef.current?.getColumns();
  if (columnState?.length) {
    columnState.forEach((item) => {
      if (item.getSort() != null) {
        gridColumnApiRef.current?.autoSizeColumn(item.getColId() || '');
        gridApiRef.current?.sizeColumnsToFit();
      }
    });
  }
};

//Filtering
export const handleFilter = (
  event: FilterChangedEvent,
  filterModel: MutableRefObject<SelectFilterModelType>,
  isServerSideFiltering: boolean | undefined = false,
  filterInfo: SelectFilterInfoType,
  usersRef: MutableRefObject<IJobStartedByUser[]>,
  dispatch: ThunkDispatch<any, any, any>,
  paginatorInfo: SelectPaginationInfoType
) => {
  const filterModelFromApi = event.api.getFilterModel();
  if (isObjectsAreEqualDeep(filterModel?.current, filterModelFromApi)) {
    return;
  }
  const dataToSend = {
    ...filterInfo,
  };
  if (isServerSideFiltering) {
    deleteTableFiltersFromDataToSend(dataToSend);
    setFilterDataToSend(
      filterModelFromApi,
      event.api,
      dataToSend,
      usersRef.current
    );
  }
  dispatch(setFilterModel(filterModelFromApi));
  dispatch(setPaginatorInfo({ ...paginatorInfo, page: 1 }));
  if (Object.keys(filterModelFromApi).length === 0) {
    dispatch(setFilterInfo({}));
  } else {
    dispatch(setFilterInfo(dataToSend));
  }
};

// Rows
export const handleRowSelection = async (
  event: RowSelectedEvent,
  getWorkflowById: AsyncThunk<IOrchestrationServer, string, {}>,
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    const isSelected = event.node.isSelected();
    const data = { ...event.node.data };
    if (isSelected) {
      const info = await dispatch(getWorkflowById(data.id))?.unwrap();
      if (info) {
        const updated = mapJobToTableData(info as IOrchestrationServer);
        dispatch(addSelectedOrchestration(updated));
      }
    } else {
      dispatch(removeSelectedOrchestration(data));
    }
  } catch (error: any) {
    consoleErrorMessage(error);
  }
};

export const handleRowDataUpdated = (
  event: RowDataUpdatedEvent,
  gridColumnApiRef: MutableRefObject<ColumnApi | null>,
  gridApiRef: MutableRefObject<GridApi<any> | null>,
  activeFilters: MutableRefObject<SelectActiveFilterType> | null,
  onGridReadyAdditionalMethods: IOnGridReadyAdditionalMethod[] | undefined
) => {
  event.api.forEachNode((rowNode: any) => {
    rowNode.setSelected(rowNode.data?.isSelected);
  });

  onGridReadyAdditionalMethods?.map(
    (additionalMethod: IOnGridReadyAdditionalMethod) => {
      if (additionalMethod.id === 'openSeverityRows') {
        additionalMethod.method(gridApiRef);
      }
      if (additionalMethod.id === 'updateDurationColumn') {
        const orchestrations: IOrchestrationRow[] = [];
        event.api.forEachNode((rowNode: any) => {
          orchestrations.push(rowNode.data);
        });
        additionalMethod.method(gridApiRef, orchestrations);
      }
    }
  );

  activeFilters?.current?.forEach((filter) => {
    gridColumnApiRef.current?.getColumn(filter.id)?.setFilterActive(true);
    gridColumnApiRef.current?.autoSizeColumn(filter.id);
  });
};
