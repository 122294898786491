import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import style from './TableOverlayComponent.module.scss';
import { PAGE_OPTIONS } from 'interfaces/table.interface';

export interface ITableOverlayComponent {
  page?: PAGE_OPTIONS;
  style?: 'fixed' | 'default';
}

const TableOverlayComponent = (props: ITableOverlayComponent) => {
  const styleOption =
    props.style === 'default' ? 'csb-table-overlay' : 'csb-table-overlay-fixed';
  return (
    <div className={style[styleOption]} data-testid="TableOverlay">
      <span className={style['csb-table-overlay-text']}>
        {props.page === PAGE_OPTIONS.errorLogs
          ? 'Please wait while your orchestration log data is loaded'
          : props.page === PAGE_OPTIONS.orchestrations
          ? 'Please wait while your orchestrations are loading'
          : props.page === PAGE_OPTIONS.manageModules
          ? 'Please wait while your modules are loading'
          : props.page === PAGE_OPTIONS.generalPage
          ? ''
          : 'Please wait while your workflows are loading'}
      </span>
      <Iconsvg name="spinner-alt" isSpin isFill size={24}></Iconsvg>
    </div>
  );
};

export default TableOverlayComponent;
