import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import React, { lazy, Suspense } from 'react';

const LazyDashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ './Dashboard')
);

const DashboardLazy = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <CsbErrorBoundary>
    <Suspense
      fallback={<TableOverlayComponent page={PAGE_OPTIONS.generalPage} />}
    >
      <LazyDashboard {...props} />
    </Suspense>
  </CsbErrorBoundary>
);

export default DashboardLazy;
