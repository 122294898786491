import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAvailableModuleServer,
  IModuleServer,
} from 'interfaces/modules/module.interface';
import {
  fetchAvailableModules,
  fetchAvailableModulesWithoutLoading,
} from 'api/manageModules/manageModulesThunks';
import { isObjectsAreEqualDeep } from 'utils/commonFunctions/CommonFunctions';
interface IManageModulesState {
  selectedModule: IModuleServer | null;
  availableModules: IAvailableModuleServer | {};
  loading: boolean;
}

export const SLICE_KEY = 'manageModules';

const initialState: IManageModulesState = {
  selectedModule: null,
  availableModules: [],
  loading: false,
};

const handleFulfilledModulesFetch = (payload: IAvailableModuleServer) => {
  return Object.fromEntries(
    Object.entries(payload).sort(([a], [b]) => a.localeCompare(b))
  );
};

const managaModulesSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setSelectedModule: (
      state,
      { payload }: PayloadAction<IModuleServer | null>
    ) => {
      return {
        ...state,
        selectedModule: payload,
      };
    },
    clearState: (state) => {
      return {
        selectedModule: null,
        availableModules: [],
        loading: false,
      };
    },
    setSelectedModuleFromRow: (
      state,
      { payload }: PayloadAction<{ id: number; slave: string } | null>
    ) => {
      let selectedModule = null;
      if (payload) {
        const moduleArray: IModuleServer[] = (
          state.availableModules as {
            [key: string]: IModuleServer[];
          }
        )[payload.slave]
          ? Array.from(
              (
                state.availableModules as {
                  [key: string]: IModuleServer[];
                }
              )[payload.slave]
            )
          : [];
        const foundModule = moduleArray.find((x) => x.id === payload.id);
        if (foundModule) {
          selectedModule = { ...foundModule, slave: payload.slave };
        }
      }
      return {
        ...state,
        selectedModule,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableModules.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchAvailableModules.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(fetchAvailableModules.fulfilled, (state, { payload }) => {
      const sortedPayload = handleFulfilledModulesFetch(payload);
      return {
        ...state,
        availableModules: sortedPayload,
        loading: false,
      };
    });
    builder.addCase(
      fetchAvailableModulesWithoutLoading.fulfilled,
      (state, { payload }) => {
        const sortedPayload = handleFulfilledModulesFetch(payload);
        let updatedSelectedModule: IModuleServer | null = null;
        let isSelectedModuleChanged = false;
        if (state.selectedModule !== null) {
          updatedSelectedModule =
            sortedPayload[state.selectedModule?.slave]?.find(
              (x) => x.id === state.selectedModule?.id
            ) ?? null;
          isSelectedModuleChanged = !isObjectsAreEqualDeep(
            state.selectedModule,
            updatedSelectedModule
          );
        }
        return {
          ...state,
          availableModules: sortedPayload,
          selectedModule: isSelectedModuleChanged
            ? updatedSelectedModule
            : state.selectedModule,
          loading: false,
        };
      }
    );
    builder.addCase(
      fetchAvailableModulesWithoutLoading.rejected,
      (state, action) => {
        return {
          ...state,
          loading: false,
        };
      }
    );
  },
});
export const selectSelectedModule = (state: any) =>
  state.manageModules.selectedModule;

export const selectAvailableModules = (state: any) =>
  state.manageModules.availableModules;

export const selectModulesLoading = (state: any) => state.manageModules.loading;

export const { setSelectedModule, setSelectedModuleFromRow, clearState } =
  managaModulesSlice.actions;

export default managaModulesSlice.reducer;
