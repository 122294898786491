import {
  IErrorLogFromServer,
  IErrorLogRow,
} from 'interfaces/error-log-row.interface';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const objectFromErrorLogData = (
  log: IErrorLogFromServer[]
): IErrorLogRow[] => {
  return log.map((item: IErrorLogFromServer) => {
    return {
      createdTime: DateUtils.formatDateFromUtcString(item.timestamp) ?? '-',
      severity: item.logLevel ?? 'INFO',
      ['Step name']:
        item.metadata?.CSBWorkflowStepName ?? 'No Step name available',
      message: item.message ?? '-',
    } as IErrorLogRow;
  });
};
