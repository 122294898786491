import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOrchestrationsServerResponse } from 'interfaces/dashboard/dashboard-slice.interface';
import { IOrchestrationServer } from 'interfaces/orchestration.interface';
import { IPostJobsInfo } from 'interfaces/post-jobs-info.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { uuidSignal } from 'utils/hooks/useSSE';

export const fetchAllOrchestrations = createAsyncThunk(
  'dashboardContainer/fetchAllOrchestrations',
  async (data: IPostJobsInfo, { rejectWithValue }) => {
    try {
      const response = await fetch(`/csb/jobs/sse/${uuidSignal}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const dataResponse: IOrchestrationsServerResponse = await response.json();
      return dataResponse;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);

export const getOrchestrationById = createAsyncThunk(
  'dashboardContainer/getOrchestrationById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetch(`/csb/jobs/${id}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: IOrchestrationServer = await response.json();
      return data;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);
