import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import React, { lazy, Suspense } from 'react';

const LazyOrchestrationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "orchestration-details" */ './OrchestrationDetails'
    )
);

const OrchestrationDetailsLazy = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <CsbErrorBoundary>
    <Suspense
      fallback={<TableOverlayComponent page={PAGE_OPTIONS.generalPage} />}
    >
      <LazyOrchestrationDetails {...props} />
    </Suspense>
  </CsbErrorBoundary>
);

export default OrchestrationDetailsLazy;
